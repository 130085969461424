import React from "react";

const counter = (function* () {
  let i = 2;
  while (true) {
    yield i++;
  }
})();

const OPERATORS = {
  choices: ["Is"],
  time: ["On", "Before", "After", "On or before", "On or after"],
  duration: [
    "Is",
    "Is greater than",
    "Is less than",
    "Is greater than or equal to",
    "Is less than or equal to",
  ],
};

const FilterSelect = ({
  name,
  operator,
  value,
  onChange,
  id,
  availableFilters,
}) => {
  const chosenFilter = availableFilters.find(
    (filter) => filter.field_name === name
  );

  return (
    <div className="filter-select__wrapper">
      <select
        value={name}
        onChange={(e) => {
          onChange({ id, name: e.target.value, operator, value });
        }}
      >
        <option value="">Select Condition</option>
        {availableFilters.map((filter) => (
          <option key={filter.field_name} value={filter.field_name}>
            {filter.name}
          </option>
        ))}
      </select>
      {chosenFilter && (
        <select
          value={operator}
          onChange={(e) => {
            onChange({ id, name, operator: e.target.value, value });
          }}
        >
          <option value="">Select Operator</option>
          {OPERATORS[chosenFilter.type].map((operator) => (
            <option key={operator} value={operator}>
              {operator}
            </option>
          ))}
        </select>
      )}
      {chosenFilter?.type === "time" && (
        <input
          type="date"
          value={value}
          onChange={(e) => {
            onChange({ id, name, operator, value: e.target.value });
          }}
        />
      )}
      {chosenFilter?.type === "duration" && (
        <label className="filter-select__inline-label">
          <input
            type="number"
            min={1}
            value={value}
            onChange={(e) => {
              onChange({ id, name, operator, value: e.target.value });
            }}
          />
          <span>Day(s)</span>
        </label>
      )}
      {chosenFilter?.type === "choices" && (
        <select
          value={value}
          onChange={(e) => {
            onChange({ id, name, operator, value: e.target.value });
          }}
        >
          <option value="">Select Choice</option>
          {Object.keys(chosenFilter.choices).map((choiceKey) => {
            if (chosenFilter.field_name === "status") {
              return (
                <option
                  key={choiceKey}
                  value={chosenFilter.choices[choiceKey][0]}
                >
                  {chosenFilter.choices[choiceKey][0]}
                </option>
              );
            }

            if (chosenFilter.field_name === "type") {
              const type = chosenFilter.choices[choiceKey];
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            }
            return (
              <option key={choiceKey} value={choiceKey}>
                {choiceKey}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default function Filters({
  availableFilters,
  chosenFilters,
  setChosenFilters,
}) {
  const handleChange = (filter) => {
    setChosenFilters((prevState) =>
      prevState.map((f) => (f.id === filter.id ? filter : f))
    );
  };

  const addCondition = () => {
    setChosenFilters((prevState) =>
      prevState.concat([
        { id: counter.next().value, name: "", operator: "", value: "" },
      ])
    );
  };

  const removeCondition = (id) => {
    setChosenFilters((prevState) => prevState.filter((f) => f.id !== id));
  };

  return (
    <div className="filters">
      <h3>Filters</h3>
      <div className="filters__list"></div>
      {chosenFilters.map((filter) => (
        <div className="filters__item">
          <button
            className="btn btn-danger"
            onClick={() => removeCondition(filter.id)}
          >
            Remove
          </button>
          <FilterSelect
            key={filter.id}
            {...filter}
            availableFilters={availableFilters}
            onChange={handleChange}
          />
        </div>
      ))}
      <button className="btn btn-default" onClick={addCondition}>
        Add Condition
      </button>
    </div>
  );
}
