import React, { useEffect } from 'react'
import useCreds from '../hooks/useCreds'
import useInvalidate from '../hooks/useInvalidate'
import FieldsChooser from './FieldsChooser'
import Filters from './Filters'

const FILTER_OPERATORS = {
  On: '$eq',
  Is: '$eq',
  Before: '$lt',
  After: '$gt',
  'On or before': '$lte',
  'On or after': '$gte',
  'Is greater than': '$gt',
  'Is less than': '$lt',
  'Is greater than or equal to': '$gte',
  'Is less than or equal to': '$lte',
}

export default function Main() {
  const apiKey = useCreds()
  const invalidate = useInvalidate()
  const [reportFields, setFields] = React.useState([])
  const [availableFilters, setFilters] = React.useState([])
  const [chosenFilters, setChosenFilters] = React.useState([
    { id: 1, name: '', operator: '', value: '' },
  ])
  const [chosenFields, setChosenFields] = React.useState([])
  const [downloadLink, setDownloadLink] = React.useState('')
  const [fetching, setFetching] = React.useState(false)
  const [reportFormat, setFormat] = React.useState('xlsx')

  useEffect(() => {
    if (!apiKey) return
    const baseUrl = ''

    const headers = {
      'X-API-KEY': apiKey,
    }

    const reportFieldsResponse = fetch(`${baseUrl}/report-fields`, {
      headers,
    }).then(res => res.json())
    const filtersResponse = fetch(`${baseUrl}/filters`, { headers }).then(res =>
      res.json()
    )

    Promise.all([reportFieldsResponse, filtersResponse])
      .then(([reportFieldsData, filtersData]) => {
        setFields(reportFieldsData)
        setFilters(filtersData)
      })
      .catch(e => {
        console.error('Error fetching fields', e)
        invalidate(e.message)
      })
  }, [apiKey, invalidate])

  const requestReport = () => {
    if (!chosenFields.length) return
    if (!apiKey) return
    if (fetching) return

    setFetching(true)
    setDownloadLink('')

    const baseUrl = ''

    const validFilters = chosenFilters.filter(
      f => f.name && f.operator && f.value
    )

    const filtersForReport = {}
    const conditions = []

    validFilters.forEach(f => {
      const filterMeta = availableFilters.find(
        fMeta => fMeta.field_name === f.name
      )
      switch (filterMeta.type) {
        case 'duration':
          conditions.push({
            [FILTER_OPERATORS[f.operator]]: f.value * 24 * 60 * 60 * 1000,
          })
          break
        case 'time':
          conditions.push({
            $expr: {
              [FILTER_OPERATORS[f.operator]]: [
                {
                  $dateToString: {
                    date: `$${f.name}`,
                    format: '%Y-%m-%d',
                  },
                },
                f.value,
              ],
            },
          })
          break
        default:
          conditions.push({
            [FILTER_OPERATORS[f.operator]]: f.value,
          })
          break
      }
    })

    if (conditions.length) {
      filtersForReport.$and = conditions
    }

    fetch(`${baseUrl}/tickets/export`, {
      method: 'POST',
      body: JSON.stringify({
        fieldsSelected: chosenFields,
        filters: filtersForReport,
        format: reportFormat,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': apiKey,
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        return Promise.reject(res.text())
      })
      .then(response => {
        const filename = response.filename

        if (filename) {
          const url = `${baseUrl}/reports/${filename}`
          setDownloadLink(url)
        }

        setFetching(false)
      })
      .catch(e => {
        console.error(e)
        setFetching(false)
      })
  }

  return (
    <div>
      <h1>Ticket Export</h1>
      <div className="form-group">
        <Filters
          availableFilters={availableFilters}
          chosenFilters={chosenFilters}
          setChosenFilters={setChosenFilters}
        />
        <FieldsChooser
          reportFields={reportFields}
          chosenFields={chosenFields}
          setChosenFields={setChosenFields}
        />
      </div>
      <div className="form-group inline-form-group">
        <label>Report Format:</label>
        <select value={reportFormat} onChange={e => setFormat(e.target.value)}>
          <option value="xlsx">Excel</option>
          <option value="csv">CSV</option>
        </select>
      </div>
      <div className="bottom-bar">
        <button
          className="btn btn-default"
          onClick={requestReport}
          disabled={fetching}
        >
          Generate Report
        </button>
        {fetching && <span>Fetching...</span>}
        {downloadLink && (
          <a href={downloadLink} className="btn btn-primary">
            Download Report
          </a>
        )}
      </div>
    </div>
  )
}
