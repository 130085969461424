import React, { useState } from 'react'
import './App.css'
import Main from './components/Main'

export const AppContext = React.createContext()

const App = () => {
  const [apiKey, setApiKey] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState('')

  const invalidate = React.useCallback(msg => {
    setError(msg)
    setSubmitted(false)
  }, [])

  return (
    <div className="App container">
      {!submitted ? (
        <form
          className="login"
          onSubmit={e => {
            e.preventDefault()
            if (apiKey.length === 0) {
              return
            }

            setSubmitted(true)
            setError('')
          }}
        >
          <h1>Login</h1>
          <div className="form-group">
            <label htmlFor="apiKey">API Key</label>
            <input
              value={apiKey}
              onChange={e => setApiKey(e.target.value)}
              type="text"
              className="form-control"
              id="apiKey"
              placeholder="Enter API Key"
            />
          </div>
          <button className="btn btn-primary">Submit</button>
          {error && <div className="alert alert-danger">{error}</div>}
        </form>
      ) : (
        <AppContext.Provider
          value={{
            apiKey,
            invalidate,
          }}
        >
          <div className="card">
            <Main />
          </div>
        </AppContext.Provider>
      )}
    </div>
  )
}

export default App
