import React from "react";

export default function FieldsChooser({
  reportFields,
  chosenFields,
  setChosenFields,
}) {
  const toggleChosenField = (field) => {
    const index = chosenFields.indexOf(field);
    if (index === -1) {
      setChosenFields([...chosenFields, field]);
    } else {
      setChosenFields(chosenFields.filter((f) => f !== field));
    }
  };

  return (
    <div className="fields-chooser">
      <h3>Select Fields</h3>
      <div className="form-group">
        <label className="filter-select__inline-label">
          <input
            type="checkbox"
            checked={chosenFields.length === reportFields.length}
            onChange={() => {
              if (chosenFields.length === reportFields.length) {
                setChosenFields([]);
              } else {
                setChosenFields(reportFields.map((f) => f.name));
              }
            }}
          />
          <span>Select All</span>
        </label>
      </div>
      <div className="fields-chooser__fields">
        {reportFields.map((field) => (
          <div className="fields-chooser__field" key={field.name}>
            <label className="filter-select__inline-label">
              <input
                type="checkbox"
                checked={!!chosenFields.find((f) => f === field.name)}
                onChange={() => toggleChosenField(field.name)}
              />
              <span>{field.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
